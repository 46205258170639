const {dj_copyObject} = require("@/@@@dj-framework/libs/function/@common");
Array.prototype.dj_subArray = function(start = 0,end = this.length) {
    if (start > end){return [];}
    if (end > this.length){end = this.length;}
    return this.slice(start,end);
}

Array.prototype.dj_containObject = function(object){
    if (object === null){return false;}
    return this.dj_objectAtIndex(object) !== -1;
}
Array.prototype.dj_objectAtIndex = function(object){
    if (Object.dj_isObject(object)){return dj_modelAtIndex(this,object.id);}
    else {return this.indexOf(object);}
}
Array.prototype.dj_intersection = function(aArray){
    return this.filter(item=>{return aArray.includes(item)})
}
Array.prototype.dj_removeObjectAtIndex = function (index){
    if (this.length > index){this.splice(index,1);}
}
Array.prototype.dj_removeFirstObject = function (){
    this.shift();
}
// Array.prototype.dj_joinToMultiArray = function(size = 50){
//     let newArr = [];
//     let childMapper
//     for (let x = 0; x < Math.ceil(this.length / size); x++) {
//         let start = x * size;
//         let end = start + size;
//         newArr.push(this.slice(start, end));
//     }
//     return newArr;
// }
Array.prototype.dj_joinToMultiEqualArray = function(size = 50){
    let newArr = [];
    for (let x = 0; x < Math.ceil(this.length / size); x++) {
        let start = x * size;
        let end = start + size;
        newArr.push(this.slice(start, end));
    }
    return newArr;
}




function dj_modelAtIndex(array,targetModel){
    let targetIndex = -1;
    this.forEach((model,index)=>{
        if (Number(model['id']) === Number(targetModel)){
            targetIndex = index
        }
    })
    return targetIndex
}


Array.prototype.dj_toString = function (sepString= '',isSort = false) {

    let mapper = this.dj_removeEmptyValue();
    if (isSort){
        let mapper = this.dj_sortASCII();
        return mapper.join(sepString);
    }else {
        return mapper.join(sepString);
    }
}
Array.prototype.dj_sortASCII = function(){
    const array = dj_copyObject(this);
    let length = array.length;
    for(let i = 0; i< length; i++){
        for(let j = i+1; j < length; j++){
            if(array[i] > array[j]){
                let temp = array[j];
                array[j]=array[i];
                array[i]=temp;
            }
        }
    }
    return array;
}
Array.prototype.dj_removeEmptyValue = function (){
    return this.filter(function (s) {return s && s.trim();});
}
Array.prototype.dj_lastObject = function(){
    if (this.length > 0){return this[this.length-1];}
    return undefined;
}
