Object.extend = function(destination, source) {
    for (let property in source) {
        destination[property] = source[property];
    }
    return destination;
}
Object.extend(Object, {
    dj_objectForKey(object,aKey = ''){
        if (!Object.dj_isString(aKey)){return undefined};
        let mapper = aKey.split('.');
        String.fromCharCode()
        return mapper
            .reduce(function (object, key) {
                    let newObject = object;
                    return object && newObject[key] !== 'undefined' ? newObject[key] : undefined;
                }, object);
    },
});