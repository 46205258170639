
Object.extend = function(destination, source) {
    for (let property in source) {
        destination[property] = source[property];
    }
    return destination;
}
Object.extend(Object, {
    dj_isEmpty(value) {
        switch (typeof value) {
            case 'undefined':
                return true;
            case 'string':
                if (value.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length === 0) {return true;}
                break;
            case 'boolean':
                if (!value) return true;
                break;
            case 'number':
                if ( isNaN(value) || value === Infinity || value === -Infinity) {return true;}
                break;
            case 'object':
                if (null === value || value.length === 0) {return true;}
                for (let i in value) {return false;}
                return true;
        }
        return false;
    },

    dj_isArray(object){
        return Object.prototype.toString.call(object) === '[object Array]';
    },
    dj_isString(object){
        return Object.prototype.toString.call(object) === '[object String]';
    },
    dj_isObject(object){
        return Object.prototype.toString.call(object) === '[object Object]';
    },
    dj_isNumber(object){
        return Object.prototype.toString.call(object) === '[object Number]';
    },
    dj_isBoolean(object){
        return Object.prototype.toString.call(object) === '[object Boolean]';
    },
    dj_isNull(object){
        return Object.prototype.toString.call(object) === '[object Null]';
    },
    dj_isUndefined(object){
        return Object.prototype.toString.call(object) === '[object Undefined]';
    },
    dj_isUrl(value){
        return /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-.\/?%&=]*)?/.test(value)
    },
//密码正确返回true，数字、大小写字母，长度在8-16位
    dj_isPassword(value){
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d]{8,16}$/.test(value)
    },

})